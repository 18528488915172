import React from "react"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"
import {withPrefix} from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Gallery from "../../components/Gallery"
import Content from "../../components/Content"

const galImgs = ['/page-images/templeburn_road_house_extension_1.jpg']

export default function AboutUs() {
  return (
    <Layout breadcrumbs={["design approach"]}>
 
<Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )} 
    />

<Content>
<p><strong>Design Approach</strong></p>
<p>	We aim to design buildings and spaces that positively impact on the lives and perceptions of their owners and users.  Our approach is to embrace tradition while incorporating innovative design and materials in combination with practical, common sense construction methods.</p>
<p>	Our response to each client’s particular wishes, coupled with the context of the site and its surroundings, is to produce a unique design solution.  We place our clients at the centre of the architectural process and believe it our role to provide guidance and support throughout the journey.  This collaboration results in completed projects that both parties can justly be proud and take ownership off in the knowledge that each has helped shape the final form.</p>

</Content>

      <div class="region region-content-bottom">
        <MenuOne active="about-us"></MenuOne>
        <MenuTwo
          active="design-approach"
          items={['../profile', '../design-approach', '../value-and-commitment', '../the-team']}>
        </MenuTwo>
      </div>

  </Layout>

  )
}
